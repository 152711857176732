"use strict";

angular.module("models").service("InsurerContractsService", ["LbService", "$rootScope", function (LbService, $rootScope) {
  var that = this;

  var params = {
    name: "insurerContracts",
    attributesForCache: ["name", "company", "brokerCompany", "insurerEdiEmail", "insurerEdiMandatoryDocs", "insurerEdiDocsAsLink"],
    formatName: function formatName(element) {
      return element.name ? element.name + (element.company ? " (" + element.company + ")" : "") + (element.brokerCompany ? " (" + element.brokerCompany + ")" : "") : "Contrat par défaut";
    },
    defaultOrder: "creationDate DESC"
  };

  angular.extend(that, LbService.getModelBis(params));

  that.getSelectArray = function (customerId) {
    return new Promise(function (resolve, reject) {
      that.find({
        filter: {
          where: {
            customerId: customerId ? customerId : $rootScope.$selectedCustomerId
          },
          fields: {
            id: true,
            company: true,
            brokerCompany: true,
            name: true
          }
        }
      }).then(function (values) {
        console.log("insurerContracts getSelectArray", values);

        var array = [];

        for (var index = 0; index < values.length; index++) {
          var response = values[index];

          array.push({
            name: (response.company ? response.company : "") + " " + (response.brokerCompany ? response.brokerCompany : "") + " (" + response.name + ")",
            value: "$" + response.id
          });
        }

        resolve(array);
      });
    });
  };
}]);