"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

(function () {
  var moduleName = "login";
  var componentName = "changePassword";

  var bindings = {
    resolve: "<?",
    close: "&?",
    dismiss: "&?"
  };

  var templateUrl = "app/pages/login/change-password.html";

  var Controller = function () {
    /** @ngInject */
    function Controller(UsersService, $rootScope) {
      _classCallCheck(this, Controller);

      this.UsersService = UsersService;
      this.$rootScope = $rootScope;
    }

    _createClass(Controller, [{
      key: "cancel",
      value: function cancel() {
        this.dismiss();
      }
    }, {
      key: "savePassword",
      value: function savePassword() {
        var _this = this;

        if (this.model.newPassword != this.model.confirmPassword) {
          Notiflix.Notify.failure("Les deux mots de passes ne sont pas identiques");
        } else if (!validator.matches(this.model.newPassword, /((?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{14,20})/)) {
          Notiflix.Notify.failure("Le mot de passe ne respecte pas le format attendu");
        } else {
          this.UsersService.updateAttributesById(this.$rootScope.$user.id, {
            password: this.model.newPassword
          }).then(function () {
            Notiflix.Notify.success("Le mot de passe a été modifié");
            _this.close();
          }).catch(function (err) {
            console.log("err", err);
            Notiflix.Notify.failure("Erreur à l'enregistrement");
          });
        }
      }
    }, {
      key: "createFields",
      value: function createFields() {
        this.fields = [{
          key: "newPassword",
          type: "input", // text

          templateOptions: {
            label: "Nouveau mot de passe",
            type: "password",
            required: true
          }
        }, {
          key: "confirmPassword",
          type: "input", // text

          templateOptions: {
            label: "Confirmation du mot de passe",
            type: "password",
            required: true
          }
        }];
      }
    }, {
      key: "$onInit",
      value: function $onInit() {
        console.log("this", this);

        this.createFields();
      }
    }, {
      key: "$onChanges",
      value: function $onChanges() {}
    }]);

    return Controller;
  }();

  angular.module(moduleName).component(componentName, {
    templateUrl: templateUrl,
    bindings: bindings,
    transclude: true,
    controller: Controller
  });
})();